<div class="container" fxFill>
  <mat-toolbar>
    <div class="logo" fxLayout="row" fxLayoutAlign="center center">
      <a href=""><img src="../../../assets/images/group-35@2x.png"></a>
    </div>
  </mat-toolbar>

  <div class="header-image-container">
    <img fxFill class="header-image" src="../../../assets/images/pexels-kelly-lacy.png">
    <div class="image-overlay"></div>

  </div>
  <div class="welcome-box">
    <h1>Välkommen till ett bättre sätt att resa.</h1>
    <button mat-raised-button color="primary" (click)="scroll()">
      <span>Börja räkna</span>
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
  <div class="header-text">
    <p>Pendlingskalkylatorn ger dig som kör bensin- eller dieseldriven bil en tydlig bild av hur mycket mindre koldioxid du släpper ut om du väljer ett annat alternativ.
      <br>Pendlingskalkylatorn ger dig också möjlighet att ta fram ett alternativt sätt att pendla anpassat för just dig.</p>
  </div>
</div>
