<div fxLayout="row" fxLayoutAlign="center start" class="container">
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <mat-form-field class="large">
        <mat-label>Välj resesätt</mat-label>
        <mat-select [(ngModel)]="travelMode">
          <mat-option value="DRIVING">Bil</mat-option>
          <mat-option value="TRANSIT">Kollektivtrafik</mat-option>
          <mat-option value="BICYCLING">Cykel</mat-option>
          <mat-option value="WALKING">Promenad</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="large">
        <mat-select [(ngModel)]="timeAlternative">
          <mat-option value="departure">Avgångstid</mat-option>
          <mat-option value="arrival">Ankomsttid</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" >
      <mat-form-field class="small" >
        <input matInput type="time" [(ngModel)]="time"/>
        <mat-icon matPrefix>access_time</mat-icon>
      </mat-form-field>

      <mat-form-field class="small">
        <input matInput type="date" [(ngModel)]="date"/>
        <mat-icon matPrefix>event</mat-icon>
      </mat-form-field>

      <button mat-raised-button color="primary" [disabled]="!travelMode" (click)="search()">
        <span>Sök</span>
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>
  </div>
</div>




