import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-stats',
  templateUrl: './info-stats.component.html',
  styleUrls: ['./info-stats.component.scss']
})
export class InfoStatsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
