<div class="footer">

  <div class="footer-top">
    <img class="background-image" src="assets/images/pexels-stuart-small.png">
    <div class="image-overlay"></div>
  </div>

  <div class="footer-bottom">

    <div class="footer-text">
      <h4>Pendlingskalkylatorn</h4>
      <p>Pendlingskalkylatorn är en tjänst tillhandahållen av Cybercom för att underlätta för pendlare som idag åker bil att hitta klimatsmartare alternativ.
        Projekt är genomfört i regi av Innovation Zone Jönköping i samarbete med studenter.</p>
    </div>

    <div class="logo-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="30px">
      <h2>Vi bakom kalkylatorn:</h2>

      <div class="logo-cybercom" fxLayout="row" fxLayoutAlign="center center">
        <img src="assets/images/cybercom_blue).svg">
      </div>

<!--      <div class="logo-climate" fxLayout="row" fxLayoutAlign="center center">-->
<!--        <img src="assets/images/bitmap@2x.png">-->
<!--      </div>-->
    </div>

  </div>
  <div class="footer-bottom-line"></div>

</div>
