<div class="box-info-stats" fxLayout="row" fxLayoutAlign="space-evenly start">
  <div class="info-stats">
    <h1>70%</h1>
    <p>
    <p>måste utsläppen av växthusgaser från transporter minska till 2030 jämfört med 2010.</p>
  </div>

  <div class="info-stats">
    <h1>30%</h1>
    <p>
    <p>är transporternas andel av Sveriges totala växthusgasutsläpp.</p>
  </div>

  <div class="info-stats">
    <h1>1,3 milj. ton</h1>
    <p>
    <p>minskade utsläppen av växthusgaser från vägtrafiken 2020. Ca 90% av minskningen beror på ändrade resvanor under pandemin.
    Vad händer med utsläppen när pandemin är över?</p>
  </div>

  <div class="info-stats">
    <h1>2045</h1>
    <p>
    <p>är året då Sverige ska vara klimatneutralt. För att uppnå detta måste transportsektorn minska sina utsläpp dramatiskt.</p>
  </div>
</div>

<div class="info-container" fxLayout="row" fxLayoutAlign="space-evenly center">
  <h3>På resvana.com kan du testa massa mer saker och hitta information om hållbara resvanor.</h3>
  <a mat-raised-button color="primary" href="https://resvana.com/" target="_blank">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span>Till resvana.com</span>
      <mat-icon>navigate_next</mat-icon>
    </div>
  </a>
</div>



