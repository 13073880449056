<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <h1>Pendlingskalkylatorn</h1>
  <mat-horizontal-stepper [linear]="true" fxFill>
    <mat-step label="Välj resrutt" [completed]="coordinatesSelected">
      <app-mvp-details (completed)="onStep1CompletedUpdate($event)"></app-mvp-details>
    </mat-step>

    <mat-step label="Välj bil" [completed]="isSelectedEmissionCarbonDioxide">
      <app-show-car
        (selectedEmissionsCarbonDioxide)="emissionsCarbonDioxideSelected($event)"
        (CarSizeSelected)="carSizeSelected()"
      ></app-show-car>
    </mat-step>

    <mat-step label="Pendlingsalternativ" [completed]="coordinatesSelected && isSelectedEmissionCarbonDioxide">
      <div *ngIf="coordinatesSelected && isSelectedEmissionCarbonDioxide">
        <app-alternative-comparison [emissionsPerKm]="selectedEmissionsCarbonDioxide"></app-alternative-comparison>
      </div>
    </mat-step>

    <mat-step label="Planera resa">
      <ng-container *ngIf="coordinatesSelected && isSelectedEmissionCarbonDioxide">
        <app-plan-trip [carEmissionsPerKm]="selectedEmissionsCarbonDioxide.emissionsCarbonDioxide"></app-plan-trip>
      </ng-container>
    </mat-step>

  </mat-horizontal-stepper>


</div>



