<!--Trip one-->
<app-trip-search-section (onSearch)="onTripOneSearch($event)"></app-trip-search-section>
<div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="tripOneSearch">
  <app-trip-instructions [steps]="tripOneDirectionSteps"></app-trip-instructions>

  <div fxLayout="column" fxLayoutGap="20px">
    <agm-map [latitude]="mapStartLat" [longitude]="mapStartLng">
      <agm-direction
        [origin]="origin"
        [destination]="destination"
        [drivingOptions]="tripOneDrivingOptions"
        [transitOptions]="tripOneTransitOptions"
        [travelMode]="tripOneTravelMode"
        (onResponse)="tripOneOnResponse($event)"
      >
      </agm-direction>
    </agm-map>
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>schedule</mat-icon>
        <div *ngIf="tripOneTimeAlternative == 'arrival'">Avgångstid: {{tripOneDepartureOrArrivalTime}}</div>
        <div *ngIf="tripOneTimeAlternative == 'departure'">Ankomsttid: {{tripOneDepartureOrArrivalTime}}</div>
      </div>
      <div fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>straighten</mat-icon>
        <span>Sträcka: {{tripOneDistance}}</span>
      </div>
      <div fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>alarm</mat-icon>
        <span>Restid: {{tripOneDuration}}</span>
      </div>
      <div fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>lens_blur</mat-icon>
        <span>Utsläpp vald resa: {{tripOneEmission | number:'1.0-0'}} gram CO2</span>
      </div>

    </div>

  </div>

</div>


<div fxLayout="row" fxLayoutAlign="center center" class="return-trip-checkbox">
  <mat-checkbox [(ngModel)]="planTripTwoChecked" [disabled]="!tripOneDirectionSteps || tripOneDirectionSteps.length === 0">Planera annan återresa</mat-checkbox>
</div>

<div [ngClass]="{'hidden':!planTripTwoChecked}">
  <!--Trip two-->
  <app-trip-search-section (onSearch)="onTripTwoSearch($event)" #tripTwoSearchSection></app-trip-search-section>
  <div fxLayout="row" fxLayoutAlign="space-between start" *ngIf="tripTwoSearch">
    <app-trip-instructions [steps]="tripTwoDirectionSteps"></app-trip-instructions>

    <div fxLayout="column" fxLayoutGap="20px">
      <agm-map [latitude]="mapStartLat" [longitude]="mapStartLng">
        <agm-direction
          [visible]="tripTwoSearch"
          [origin]="destination"
          [destination]="origin"
          [drivingOptions]="tripTwoDrivingOptions"
          [transitOptions]="tripTwoTransitOptions"
          [travelMode]="tripTwoTravelMode"
          (onResponse)="tripTwoOnResponse($event)"
        >
        </agm-direction>
      </agm-map>

      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>schedule</mat-icon>
          <div *ngIf="tripTwoTimeAlternative == 'arrival'">Avgångstid: {{tripTwoDepartureOrArrivalTime}}</div>
          <div *ngIf="tripTwoTimeAlternative == 'departure'">Ankomsttid: {{tripTwoDepartureOrArrivalTime}}</div>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>straighten</mat-icon>
          <span>Sträcka: {{tripTwoDistance}}</span>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>alarm</mat-icon>
          <span>Restid: {{tripTwoDuration}}</span>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-icon>lens_blur</mat-icon>
          <span>Utsläpp vald resa: {{tripTwoEmission | number:'1.0-0'}} gram CO2</span>
        </div>
      </div>

    </div>
  </div>

</div>


<div class="stepper-buttons" fxLayout="row" fxLayoutAlign="space-between center" fxFill>
  <button mat-raised-button color="accent" matStepperPrevious>
    <mat-icon>navigate_before</mat-icon>
    <span>Bakåt</span>
  </button>

  <button class="no-icon" mat-raised-button color="primary" (click)="downloadPdf()" [disabled]="!tripOneDirectionSteps || tripOneDirectionSteps.length === 0">
    <span>Spara PDF</span>
  </button>
</div>






