import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from "@angular/common";
import {DirectionStep} from "../../models/direction-step.model";
import {TripService} from "../../services/trip.service";
import {DrivingOptions, TransitOptions, TripSearch} from "../../models/trip-search.model";
import {TripSearchSectionComponent} from "./trip-search-section/trip-search-section.component";

@Component({
  selector: 'app-plan-trip',
  templateUrl: './plan-trip.component.html',
  styleUrls: ['./plan-trip.component.scss']
})
export class PlanTripComponent implements OnInit {
  @ViewChild("tripTwoSearchSection", { static: false }) public tripTwoSearchSection!: TripSearchSectionComponent;

  @Input() carEmissionsPerKm: number | undefined = 0;
  origin: any;
  destination:any;
  originText: string = "";
  destinationText: string = "";
  mapStartLng: number = 14.155032;
  mapStartLat: number = 57.770562;

  // @ts-ignore
  tripOneTravelMode: google.maps.TravelMode = "";
  // @ts-ignore
  tripOneDrivingOptions: google.maps.DrivingOptions | undefined = undefined;
  // @ts-ignore
  tripOneTransitOptions: google.maps.TransitOptions | undefined = undefined;
  tripOneSearch: boolean = false;
  tripOneDirectionSteps: DirectionStep[] = [];
  tripOneDistance: string = "";
  tripOneDistanceMeter: number = 0;
  tripOneDuration: string = "";
  tripOneTimeAlternative: string = "";
  tripOneTime: string = "";
  tripOneDate: string = "";
  tripOneDepartureOrArrivalTime: string = "";
  tripOneEmission: number = 0;


  // @ts-ignore
  tripTwoTravelMode: google.maps.TravelMode = "";
  // @ts-ignore
  tripTwoDrivingOptions: google.maps.DrivingOptions | undefined = undefined;
  // @ts-ignore
  tripTwoTransitOptions: google.maps.TransitOptions | undefined = undefined;
  planTripTwoChecked: boolean = false;
  tripTwoSearch: boolean = false;
  tripTwoDirectionSteps: DirectionStep[] = [];
  tripTwoDistance: string = "";
  tripTwoDistanceMeter: number = 0;
  tripTwoDuration: string = "";
  tripTwoDurationSeconds: number = 0;
  tripTwoTimeAlternative: string = "";
  tripTwoTime: string = "";
  tripTwoDate: string = "";
  tripTwoDepartureOrArrivalTime: string = "";
  tripTwoEmission: number = 0;

  constructor(public datepipe: DatePipe, public tripService: TripService) {
  }

  ngOnInit(): void {
    this.tripService.tripCoordinates.subscribe(coordinates => {
      this.origin = coordinates.origin;
      this.destination = coordinates.destination;
      this.originText = coordinates.originText;
      this.destinationText = coordinates.destinationText;
    });
  }

  tripOneOnResponse($event: google.maps.DirectionsResult) {
    let leg = $event.routes[0].legs[0];
    this.tripOneDirectionSteps = this.tripService.ProcessSteps(leg, this.tripOneTravelMode);
    this.tripOneDistance = leg.distance.text;
    this.tripOneDistanceMeter = leg.distance.value;
    this.tripOneDuration = leg.duration.text;

    let selectedDate = new Date(this.tripOneDate + " " + this.tripOneTime);

    if (this.tripOneTimeAlternative === "departure") {
      selectedDate.setSeconds(selectedDate.getSeconds() + leg.duration.value);
    } else {
      selectedDate.setSeconds(selectedDate.getSeconds() - leg.duration.value);
    }
    this.tripOneDepartureOrArrivalTime = this.datepipe.transform(selectedDate, 'HH:mm') ?? "";

    this.tripOneEmission = this.tripService.CalculateEmissions(leg, this.tripOneTravelMode, this.carEmissionsPerKm!);
  }

  tripTwoOnResponse($event: google.maps.DirectionsResult) {
    let leg = $event.routes[0].legs[0];
    this.tripTwoDirectionSteps = this.tripService.ProcessSteps(leg, this.tripTwoTravelMode);
    this.tripTwoDistance = leg.distance.text;
    this.tripTwoDistanceMeter = leg.distance.value;
    this.tripTwoDuration = leg.duration.text;
    this.tripTwoDurationSeconds = leg.duration.value;

    let selectedDate = new Date(this.tripOneDate + " " + this.tripOneTime);

    if (this.tripTwoTimeAlternative === "departure") {
      selectedDate.setSeconds(selectedDate.getSeconds() + leg.duration.value);
    } else {
      selectedDate.setSeconds(selectedDate.getSeconds() - leg.duration.value);
    }
    this.tripTwoDepartureOrArrivalTime = this.datepipe.transform(selectedDate, 'HH:mm') ?? "";

    this.tripTwoEmission = this.tripService.CalculateEmissions(leg, this.tripTwoTravelMode, this.carEmissionsPerKm!);
  }


  onTripOneSearch(event: TripSearch) {
    // @ts-ignore
    this.tripOneTravelMode = event.travelMode;
    // @ts-ignore
    this.tripOneDrivingOptions = event.drivingOptions;
    // @ts-ignore
    this.tripOneTransitOptions = event.transitOptions;
    this.tripOneTimeAlternative = event.timeAlternative;
    this.tripOneTime = event.time;
    this.tripOneDate = event.date;
    this.tripOneSearch = true;
    this.tripTwoSearchSection.setTimeAndDate(this.tripOneDate, this.tripOneTime);
  }

  onTripTwoSearch(event: TripSearch) {
    // @ts-ignore
    this.tripTwoTravelMode = event.travelMode;
    // @ts-ignore
    this.tripTwoDrivingOptions = event.drivingOptions;
    // @ts-ignore
    this.tripTwoTransitOptions = event.transitOptions;
    this.tripTwoTimeAlternative = event.timeAlternative;
    this.tripTwoTime = event.time;
    this.tripTwoDate = event.date;
    this.tripTwoSearch = true;
  }


  downloadPdf() {
    this.tripService.getTripPlanPdf(
      this.tripOneDirectionSteps,
      this.tripOneTimeAlternative === "departure" ? this.tripOneTime : this.tripOneDepartureOrArrivalTime,
      this.tripOneTimeAlternative === "arrival" ? this.tripOneTime : this.tripOneDepartureOrArrivalTime,
      this.originText,
      this.destinationText,
      this.tripTwoDirectionSteps,
      this.tripTwoTimeAlternative === "departure" ? this.tripTwoTime : this.tripTwoDepartureOrArrivalTime,
      this.tripTwoTimeAlternative === "arrival" ? this.tripTwoTime : this.tripTwoDepartureOrArrivalTime,
      this.destinationText,
      this.originText);
  }
}
