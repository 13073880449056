import {Component, Input, OnInit} from '@angular/core';
import {DirectionStep} from "../../models/direction-step.model";

@Component({
  selector: 'app-trip-instructions',
  templateUrl: './trip-instructions.component.html',
  styleUrls: ['./trip-instructions.component.scss']
})
export class TripInstructionsComponent implements OnInit {
  @Input("steps")
  directionSteps: DirectionStep[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
