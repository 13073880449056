import { AppComponent } from './app.component';
import { MvpDetailsComponent } from './components/mvp-details/mvp-details.component';
import { AgmDirectionModule } from 'agm-direction';
import { ShowCarComponent } from './components/show-car/show-car.component';
import { ChartComponent } from './components/chart/chart.component';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { LOCALE_ID } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import {MatSelectModule} from "@angular/material/select";
import {environment} from '../environments/environment';
import {MatRadioModule } from '@angular/material/radio';
import { TripInstructionsComponent } from './components/trip-instructions/trip-instructions.component';
import { PlanTripComponent } from './components/plan-trip/plan-trip.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { TripSearchSectionComponent } from './components/plan-trip/trip-search-section/trip-search-section.component';
import { HeaderComponentComponent } from './components/header-component/header-component.component';
import {MatToolbarModule} from "@angular/material/toolbar";
import { InfoSquaresComponent } from './components/info-squares/info-squares.component';
import { InfoStatsComponent } from './components/info-stats/info-stats.component';
import { FooterComponent } from './components/footer/footer.component';
import { CommutingCalculateComponent } from './components/commuting-calculate/commuting-calculate.component';
import { AlternativeComparisonComponent } from './components/alternative-comparison/alternative-comparison.component';
import { AlternativeInfoComponent } from './components/alternative-comparison/alternative-info/alternative-info.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
registerLocaleData(localeSv);

@NgModule({
  declarations: [
    AppComponent,
    MvpDetailsComponent,
    ShowCarComponent,
    ChartComponent,
    TripInstructionsComponent,
    PlanTripComponent,
    TripSearchSectionComponent,
    HeaderComponentComponent,
    InfoSquaresComponent,
    InfoStatsComponent,
    FooterComponent,
    CommutingCalculateComponent,
    AlternativeComparisonComponent,
    AlternativeInfoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule,
    MatListModule,
    MatToolbarModule,
    MatStepperModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_API_KEY,
      libraries: ['places', 'geometry'],
    }),
    AgmDirectionModule,
    MatSelectModule,
    MatRadioModule,
    MatAutocompleteModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'sv-SE' }, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
