import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {TripService} from "../../../services/trip.service";

@Component({
  selector: 'app-alternative-info',
  templateUrl: './alternative-info.component.html',
  styleUrls: ['./alternative-info.component.scss']
})
export class AlternativeInfoComponent implements OnInit {
  @Input() travelMode: string = "";
  @Input() carEmissionsPerKm: number | undefined = 0;
  @Output() emissionsCalculated = new EventEmitter<number>();

  origin: any;
  destination: any;
  distance: string = "";
  distanceMeter: number = 0;
  duration: string = "";
  durationSeconds: number = 0;
  emissions: number = 0;

  constructor(private tripService: TripService) { }

  ngOnInit(): void {
    this.tripService.tripCoordinates.subscribe(coordinates => {
      this.origin = coordinates.origin;
      this.destination = coordinates.destination;

      if (this.origin && this.destination && this.travelMode) {
        this.getDirections();
      }
    });
  }

  getDirections(){
    let directionsService = new google.maps.DirectionsService();
    let travelMode: google.maps.TravelMode = google.maps.TravelMode.DRIVING;

    switch (this.travelMode){
      case "DRIVING":
        travelMode = google.maps.TravelMode.DRIVING;
        break;
      case "TRANSIT":
        travelMode = google.maps.TravelMode.TRANSIT;
        break;
      case "BICYCLING":
        travelMode = google.maps.TravelMode.BICYCLING;
        break;
      case "WALKING":
        travelMode = google.maps.TravelMode.WALKING;
        break;
    }

    let request = {
      origin: this.origin,
      destination: this.destination,
      travelMode: travelMode
    };

    directionsService.route(request, (response) => {
      this.OnResponse(response);
    });
  }

  OnResponse(response: google.maps.DirectionsResult) {
    if (response && response.routes && response.routes.length > 0) {
      let leg = response.routes[0].legs[0];
      this.distance = leg.distance.text;
      this.distanceMeter = leg.distance.value;
      this.duration = leg.duration.text;
      this.durationSeconds = leg.duration.value;

      this.CalculateEmissions(leg);
    }
  }

  CalculateEmissions(leg: google.maps.DirectionsLeg){
    this.emissions = this.tripService.CalculateEmissions(leg, this.travelMode, this.carEmissionsPerKm!);
    this.emissions = this.emissions * 2 * 20;
    this.emissionsCalculated.emit(this.emissions);
  }
}
