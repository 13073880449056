import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {TripCoordinates} from "../models/location.model";
import {HttpClient} from "@angular/common/http";
import {DirectionStep} from "../models/direction-step.model";
import { CalculatedEmission } from '../models/calculated-emission.model';
import {StepTravelMode} from "../enums/step-travel-mode.enum";
import DirectionsStep = google.maps.DirectionsStep;

@Injectable({
  providedIn: 'root',
})
export class TripService {
  private apiUrl: string = "https://api.pendlingskalkylatorn.se/api";
  private _carTripEmissions: BehaviorSubject<CalculatedEmission> = new BehaviorSubject<CalculatedEmission>(
    { calculatedCarEmission: 0, calculatedTransitEmission: 0 }
  );

  private _tripCoordinates: BehaviorSubject<TripCoordinates> = new BehaviorSubject<TripCoordinates>({
    origin: null,
    originText: "",
    destination: null,
    destinationText: ""
  });

  private _travelMode: BehaviorSubject<string> = new BehaviorSubject<string>('DRIVING');

  public carTripEmissions: Observable<CalculatedEmission> = this._carTripEmissions.asObservable();
  public readonly tripCoordinates: Observable<TripCoordinates> = this._tripCoordinates.asObservable();
  public readonly travelMode: Observable<string> = this._travelMode.asObservable();


  // emission per km  for different travelmode.
  private busEmissionPerKm: number = 27;
  private trainEmissionPerKm: number =10;
  private ferryEmissionPerKm: number = 170;

  constructor(private http: HttpClient) {
  }

  // update the coordinates for the trip.
  public updateTripCoordinates(coordinates: TripCoordinates) {
    this._tripCoordinates.next(coordinates);
  }

  // update the selected the travelmode for the trip
  public updateTravelMode(travelMode: string){
    this._travelMode.next(travelMode);
  }

  // update car emissions from step 2.
  UpdateTripEmmisions(emissionCalculate: CalculatedEmission) {
    this._carTripEmissions.next(emissionCalculate);
  }

  public getTripPlanPdf(

    tripOneSteps: DirectionStep[],
    tripOneDepartureTime: string,
    tripOneArrivalTime: string,
    tripOneOriginText: string,
    tripOneDestinationText:string,
    tripTwoSteps: DirectionStep[],
    tripTwoDepartureTime: string,
    tripTwoArrivalTime: string,
    tripTwoOriginText:string,
    tripTwoDestinationText: string){

    let body = {
      tripOne: {
        steps: tripOneSteps,
        arrivalTime: tripOneArrivalTime,
        departureTime: tripOneDepartureTime,
        originText: tripOneOriginText,
        destinationText: tripOneDestinationText,
      },
      tripTwo:{
        steps: tripTwoSteps,
        arrivalTime: tripTwoArrivalTime,
        departureTime: tripTwoDepartureTime,
        originText: tripTwoOriginText,
        destinationText: tripTwoDestinationText,
      }
    };

    // Get pdf file from API and download as PDF Document.
    this.http.post(`${this.apiUrl}/PdfCreator`, body, {responseType: 'blob'}).subscribe((pdf: any) => {
      const blob = new Blob([pdf], { type: 'application/pdf' });
      const fileName = 'Resplan.pdf';
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);

    });
  }

  CalculateEmissions(leg: google.maps.DirectionsLeg, travelMode: any, carEmissionsPerKm: number){
    let distanceMeter = leg.distance.value;
    let emissions = 0;

    if (travelMode === "DRIVING") {
      emissions = (distanceMeter / 1000 * carEmissionsPerKm!);
    } else if (travelMode === "TRANSIT") {
      // calculate transit emission.
      for (let i = 0; i < leg.steps.length; i++) {
        let step: DirectionsStep = leg.steps[i];

        if(step.travel_mode == "TRANSIT"){
          let distance = step.distance.value / 1000;

          switch (step.transit.line.vehicle.type.toString()){
            case "COMMUTER_TRAIN":
            case "HEAVY_RAIL":
            case "HIGH_SPEED_TRAIN":
            case "METRO_RAIL":
            case "SUBWAY":
            case "RAIL":
            case "TRAM":
            case "MONORAIL":
              // this step is a train.
              emissions += distance * this.trainEmissionPerKm;
              break;
            case "BUS":
            case "TROLLEYBUS":
            case "INTERCITY_BUS":
              // this step is a bus.
              emissions += distance * this.busEmissionPerKm;
              break;
            case "FERRY":
              // this step is a ferry.
              emissions += distance * this.ferryEmissionPerKm;
              break;
          }
        }
      }
    }

    return emissions;
  }

  public ProcessSteps(leg: google.maps.DirectionsLeg, selectedTravelMode: string) : DirectionStep[] {
    let list: DirectionStep[] = [];

    for (let i = 0; i < leg.steps.length; i++) {
      let step: DirectionsStep = leg.steps[i];

      if (step.travel_mode == "TRANSIT") {
        let travelMode: StepTravelMode = 0;

        switch (step.transit.line.vehicle.type.toString()) {
          case "COMMUTER_TRAIN":
          case "HEAVY_RAIL":
          case "HIGH_SPEED_TRAIN":
          case "METRO_RAIL":
          case "SUBWAY":
          case "RAIL":
          case "TRAM":
          case "MONORAIL":

            // this step is a train.
            travelMode = StepTravelMode.Train;
            break;
          case "BUS":
          case "TROLLEYBUS":
          case "INTERCITY_BUS":

            // this step is a bus.
            travelMode = StepTravelMode.Bus;
            break;
          case "FERRY":

            // this step is a ferry.
            travelMode = StepTravelMode.Ferry;
            break;
        }

        let directionStep: DirectionStep = {
          distance: step.distance.text,
          duration: step.duration.text,
          instructions: step.instructions,
          isTransit: true,
          arrivalStop: step.transit.arrival_stop.name,
          arrivalTime: step.transit.arrival_time.text,
          departureStop: step.transit.departure_stop.name,
          departureTime: step.transit.departure_time.text,
          transitLine: step.transit.line.short_name,
          travelMode: travelMode
        };

        list.push(directionStep)

      } else {
        let stepTravelMode = StepTravelMode.Walking;

        if (selectedTravelMode == "DRIVING") {
          stepTravelMode = StepTravelMode.Driving;
        } else if (selectedTravelMode == "BICYCLING") {
          stepTravelMode = StepTravelMode.Bicycling;
        }

        let directionStep: DirectionStep = {
          distance: step.distance.text,
          duration: step.duration.text,
          instructions: step.instructions,
          travelMode: stepTravelMode,
          isTransit: false,
          arrivalStop: "",
          arrivalTime: "",
          departureStop: "",
          departureTime: "",
          transitLine: ""
        };

        list.push(directionStep)
      }
    }

    return list;
  }
}
