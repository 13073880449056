<div fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>straighten</mat-icon>
    <span>Sträcka: {{distance}}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>alarm</mat-icon>
    <span>Tid: {{duration}}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-icon>lens_blur</mat-icon>
    <span>Utsläpp: {{emissions | number:'1.0-0'}} gram CO2/mån</span>
  </div>
</div>
