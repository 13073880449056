<div class="container">
  <h1 class="header">Varför sluta köra bil till jobbet?</h1>
  <div fxLayout="row" fxLayoutAlign="center center">

    <div class="box one">
      <div class="logo-box" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>fingerprint</mat-icon>
      </div>
      <h1>Minskade utsläpp</h1>

      <div class="text-box">
        <p>Personbilar står idag för ca 60% av koldioxidutsläppen från transporter inom EU. </p>
      </div>
    </div>

    <div class="box two">
      <div class="logo-box" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>schedule</mat-icon>
      </div>
      <h1>Många resor är korta</h1>

      <div class="text-box">
        <p>Enligt Trafikverket är hälften av alla bilresor inom tätort kortare än 5 km. </p>
      </div>
    </div>

    <div class="box three">
      <div class="logo-box" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>whatshot</mat-icon>
      </div>
      <h1>Det blir billigare</h1>

      <div class="text-box">
        <p>Att pendla med bil är dyrt. Att åka kollektivt, cykla eller gå är mycket billigare. </p>
      </div>
    </div>
  </div>
</div>
<div class="spacing"></div>










