import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {ShowCarService} from 'src/app/services/show-car.service';
import {CarManufacturer} from 'src/app/models/car-manufacturer.model';
import {CarModel} from 'src/app/models/car-model.model';
import {YearModel} from 'src/app/models/year-model.model';
import {Engine} from 'src/app/models/engine.model';
import {SelectedCar} from 'src/app/models/selected-car.model';

@Component({
  selector: 'app-show-car',
  templateUrl: './show-car.component.html',
  styleUrls: ['./show-car.component.scss'],
})
export class ShowCarComponent implements OnInit {
  // ***************This is for CommutingCalculatorAPI**********************
  @Output() CarSizeSelected = new EventEmitter<SelectedCar[]>();
  @Output() selectedEmissionsCarbonDioxide = new EventEmitter<SelectedCar>();
  @ViewChild('selectedCar')
  public selectedCar!: HTMLInputElement;

  selectedCars: SelectedCar[] = [];
  selectedValueOfFuel: string = '1';
  selectedSizeOfCar: string = '';
  selectedCarsByValueOfFuel: SelectedCar[] = [];
  selectedCarsBySizeOfCar: SelectedCar[] = [];
  selectedValueOfYearModel!: SelectedCar;

  petrol: SelectedCar[] = [];
  diesel: SelectedCar[] = [];
  isSelectedCarsize: boolean = false;
  isSelectedEmissionsCarbonDioxide: boolean = false;

  constructor(private carService: ShowCarService) {
  }

  ngOnInit(): void {
    // this.getCarsAndInit(); This is for the CommutingAPI
    this.getSelectedCarAndInit();
  }

  // Get the selectedCars from Database and subscribe the data
  getSelectedCarAndInit() {
    this.carService.GetSelectedCars().subscribe((data: SelectedCar[]) => {
      this.selectedCars = data;
      this.petrol = this.setValueOfFuel(1);
      this.diesel = this.setValueOfFuel(2);
    });
  }

  // This sets the array matched on fuel on load
  setValueOfFuel(choiceOfFuel: number): SelectedCar[] {
    return this.selectedCars.filter(
      (selectedCar: SelectedCar) => choiceOfFuel == selectedCar.fuel
    );
  }

  // When carsize is changed sort array depending on carsize
  carSizeChanged() {
    const choiceOfSize = parseInt(this.selectedSizeOfCar);
    this.isSelectedEmissionsCarbonDioxide = false;
    if (this.selectedValueOfFuel === '1') {
      this.selectedCarsBySizeOfCar = this.setCarSizevalue(
        this.petrol,
        choiceOfSize
      );
      this.isSelectedCarsize = true;
      this.CarSizeSelected.emit(this.selectedCarsBySizeOfCar);
    } else if (this.selectedValueOfFuel === '2') {
      this.selectedCarsBySizeOfCar = this.setCarSizevalue(
        this.diesel,
        choiceOfSize
      );
      this.isSelectedCarsize = true;
      this.CarSizeSelected.emit(this.selectedCarsBySizeOfCar);
    }
  }

  // Sets the array depending on selected size
  setCarSizevalue(fuel: SelectedCar[], choiceOfSize: number): SelectedCar[] {
    return (this.selectedCarsBySizeOfCar = fuel.filter(
      (selectedCar: SelectedCar) => choiceOfSize === selectedCar.carSize
    ));
  }

  // Destoy value when changing fueloption
  fuelOptionChanged() {
    this.isSelectedCarsize = false;
    this.isSelectedEmissionsCarbonDioxide = false;
    this.selectedSizeOfCar = '';
    this.selectedCar.value = '';
    this.CarSizeSelected.emit(this.selectedCarsBySizeOfCar);
  }

  // When modelyear is changed sort array depending on modelyear
  carModelYearChanged(selectedId: any) {
    if (!selectedId) {
      this.isSelectedEmissionsCarbonDioxide = false;
      this.selectedEmissionsCarbonDioxide.emit(this.selectedValueOfYearModel);
    } else if (selectedId) {
      this.isSelectedEmissionsCarbonDioxide = true;
      this.selectedEmissionsCarbonDioxide.emit(this.selectedValueOfYearModel);
    }
  }
}
