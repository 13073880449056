<div class="container-alternativ">

  <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="40px">

    <div class="description" fxLayout="row" fxLayoutAlign="center center">
      <span>Vilken bil kör du med idag?</span>
    </div>


    <mat-radio-group fxLayout="row" fxLayoutGap="20px"
                     aria-labelledby="radio-group-label"
                     [(ngModel)]="selectedValueOfFuel"
                     (change)="fuelOptionChanged()">
      <mat-radio-button value="1" checked>Bensin</mat-radio-button>
      <mat-radio-button value="2">Diesel</mat-radio-button>
    </mat-radio-group>


    <div fxLayout="column" fxLayoutGap="20px">
      <mat-form-field class="large">
        <mat-label>Storlek på bil</mat-label>
        <mat-select (selectionChange)="carSizeChanged()" [(ngModel)]="selectedSizeOfCar">
          <mat-option value="1"> Liten bil (t.ex. VW Polo)</mat-option>
          <mat-option value="2"> Mellanstor bil (t.ex. Skoda Octavia)</mat-option>
          <mat-option value="3"> Stor bil (t.ex. Volvo V70)</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="large">
        <mat-label>Årsmodell</mat-label>
        <mat-select (selectionChange)="carModelYearChanged(selectedCar.value.id)"
                    [(ngModel)]="selectedValueOfYearModel"
                    [disabled]="!isSelectedCarsize" #selectedCar>
          <mat-option [value]="selectedSize" *ngFor="let selectedSize of selectedCarsBySizeOfCar">
            {{ selectedSize.yearModel }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="stepper-buttons" fxLayout="row" fxLayoutAlign="space-between center" fxFill>
      <button mat-raised-button color="accent" matStepperPrevious>
        <mat-icon>navigate_before</mat-icon>
        <span>Bakåt</span>
      </button>

      <button mat-raised-button color="primary" matStepperNext [disabled]="!isSelectedEmissionsCarbonDioxide">
        <span>Nästa</span>
        <mat-icon>navigate_next</mat-icon>
      </button>
    </div>

  </div>

</div>
