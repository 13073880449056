<div fxLayout="column" fxLayoutGap="40px">

  <div class="description" fxLayout="row" fxLayoutAlign="center center">
    <span>Fyll i vilken sträcka du vanligtvis reser.</span>
  </div>

  <div class="route-form">

    <div class="errors">

      <div *ngIf="originSearch.value && destinationSearch.value && destinationSearch.value === originSearch.value" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-icon>warning_amber</mat-icon>
        <p>Startadress och destination kan inte vara samma.</p>
      </div>

      <div *ngIf="originNoResultError" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-icon>warning_amber</mat-icon>
        <p>Det fanns inget resultat för startadress.</p>
      </div>

      <div *ngIf="destinationNoResultError" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-icon>warning_amber</mat-icon>
        <p>Det fanns inget resultat för destinationen.</p>
      </div>
    </div>

    <form [formGroup]="form" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <mat-form-field class="large">
        <mat-label>Startadress</mat-label>
        <input type="text"
               #originSearch
               matInput
               required
               formControlName="originSearch"
               [matAutocomplete]="originAuto">
        <mat-autocomplete autoActiveFirstOption #originAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="originSelected($event)">
          <mat-option *ngFor="let option of originResults | async" [value]="option">
            {{option.description}}
          </mat-option>
        </mat-autocomplete>




        <mat-error *ngIf="f.originSearch.touched && f.originSearch.invalid" class="alert alert-danger">
          <mat-error *ngIf="f.originSearch.errors?.required">Du måste välja en startadress</mat-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="large">
        <mat-label>Destination</mat-label>
        <input type="text"
               #destinationSearch
               matInput
               required
               formControlName="destinationSearch"
               [matAutocomplete]="destinationAuto">
        <mat-autocomplete autoActiveFirstOption #destinationAuto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="destinationSelected($event)">
          <mat-option *ngFor="let option of destinationResults | async" [value]="option">
            {{option.description}}
          </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="f.destinationSearch.touched && f.destinationSearch.invalid" class="alert alert-danger">
          <mat-error *ngIf="f.destinationSearch.errors?.required">Du måste välja en destionation</mat-error>
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="stepper-buttons" fxLayout="row" fxLayoutAlign="end end">
    <button mat-raised-button color="primary" matStepperNext [disabled]="!this.origin || !this.destination || (destinationSearch.value===originSearch.value)" >
      <span>Nästa</span>
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>

