import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DatePipe} from "@angular/common";
import {DrivingOptions, TransitOptions, TripSearch} from "../../../models/trip-search.model";

@Component({
  selector: 'app-trip-search-section',
  templateUrl: './trip-search-section.component.html',
  styleUrls: ['./trip-search-section.component.scss']
})
export class TripSearchSectionComponent implements OnInit {
  @Output() onSearch = new EventEmitter<TripSearch>();

  travelMode: any;
  timeAlternative: string = "departure";
  time: string = "";
  date: string = "";
  minDate: Date = new Date();

  constructor(public datepipe: DatePipe) { }

  ngOnInit(): void {
    let now = new Date;
    let coeff = 1000 * 60;
    let rounded = new Date(Math.ceil(now.getTime() / coeff) * coeff);
    this.time = this.datepipe.transform(rounded, 'HH:mm') ?? "";
    this.date = this.datepipe.transform(rounded, 'yyyy-MM-dd') ?? "";
    this.minDate = rounded;
  }

  search() {
    let transitOptions: TransitOptions | undefined;
    let drivingOptions: DrivingOptions | undefined;

    if (this.travelMode == "DRIVING") {
      transitOptions = undefined;

      if (this.timeAlternative == "departure") {
        drivingOptions = {
          departureTime: new Date(this.date + " " + this.time)
        };
      } else {
        drivingOptions = undefined;
      }
    } else if (this.travelMode == "TRANSIT") {
      drivingOptions = undefined;

      if (this.timeAlternative == "departure") {
        transitOptions = {
          departureTime: new Date(this.date + " " + this.time),
          arrivalTime: null
        };
      } else {
        transitOptions = {
          arrivalTime: new Date(this.date + " " + this.time),
          departureTime: null
        };
      }
    } else {
      drivingOptions = undefined;
      transitOptions = undefined;
    }

    let tripSearch: TripSearch = {
      travelMode: this.travelMode,
      timeAlternative: this.timeAlternative,
      time:this.time,
      date:this.date,
      drivingOptions:drivingOptions,
      transitOptions:transitOptions
    };

    this.onSearch.emit(tripSearch);
  }

  public setTimeAndDate(date: string, time: string){
    this.date = date;
    this.time = time;
  }
}
