<div *ngIf="carTripEmissions">
<div class="chart">
  <div class="content" fxLayout="row" >
    <div class="bars" *ngFor="let item of chartModels" fxLayout="column" fxLayoutAlign="end center">
      <span>{{ item.value | number:'1.0-0'}}</span>
      <div
        class="bar"
        [style.background-color]="item.color"
        [style.height]="item.size"
      ></div>
    </div>
  </div>
  <div class="line"></div>
  <div class="legends" fxLayout="row">
    <span *ngFor="let item of chartModels">{{ item.legend }}</span>
  </div>
  <p class="emission-text">gram CO2/månad</p>
</div>
</div>
