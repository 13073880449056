import { Component, OnInit} from '@angular/core';
import { Chart } from 'src/app/models/chart.model';
import { TripService } from '../../services/trip.service';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  public total = 0;
  public maxHeight = 160;
  carTripEmissions: number = 0;
  transitEmissions: number = 0;

  //Array that holds allt the bars
  public chartModels: Array<Chart> = [
    { value: 0, color: '#1b4343', size: '', legend: 'Bil' },
    { value: 0, color: '#c8dddb', size: '', legend: 'Kollektivtrafik' },
    { value: 0, color: '#3a5948', size: '', legend: 'Cykel' },
    { value: 0, color: '#1f8f53', size: '', legend: 'Gå' },
  ];

  constructor(private tripService: TripService) {}

  ngOnInit(): void {
    this.getEmissonsAndInit();
  }

// Get Emissions and initialize varables. Round the values to the nearest integer.
  private getEmissonsAndInit() {
    this.tripService.carTripEmissions.subscribe((emissions) => {
      this.carTripEmissions = emissions.calculatedCarEmission;
      this.transitEmissions = emissions.calculatedTransitEmission;
      this.chartModels[0].value = Math.round(this.carTripEmissions);
      this.chartModels[1].value = Math.round(this.transitEmissions);
      this.total = 0;
      this.mountGraphic();
    });
  }

 private mountGraphic() {
    //Calculate the total by adding all the values
    this.chartModels.forEach((element) => {
      this.total += element.value;
    });

    //Calculate the size of each bar using the following calculation: (value*maxHeight)/total
    this.chartModels.forEach((element) => {
      element.size =
        Math.round((element.value * this.maxHeight) / this.total) + '%';
    });
  }
}
