import { Component, OnInit } from '@angular/core';
import {SelectedCar} from "../../models/selected-car.model";

@Component({
  selector: 'app-commuting-calculate',
  templateUrl: './commuting-calculate.component.html',
  styleUrls: ['./commuting-calculate.component.scss']
})
export class CommutingCalculateComponent implements OnInit {
  isSelectedEmissionCarbonDioxide: boolean = false;
  selectedEmissionsCarbonDioxide: SelectedCar = {
    id: 0,
    fuel: 0,
    carSize: 0,
    yearModel: 0,
    emissionsCarbonDioxide: 0,
  };

  coordinatesSelected: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
  }


  emissionsCarbonDioxideSelected(selectedCarEmission: SelectedCar) {
    if (selectedCarEmission) {
      this.selectedEmissionsCarbonDioxide = selectedCarEmission;
      this.isSelectedEmissionCarbonDioxide = true;
    } else if (!selectedCarEmission) {
      this.isSelectedEmissionCarbonDioxide = false;
    }
  }

  //This is a flag that determines if user has selected all required dropdowns
  carSizeSelected() {
    this.isSelectedEmissionCarbonDioxide = false;
  }

  onStep1CompletedUpdate(completed: boolean) {
    this.coordinatesSelected = completed;
  }
}
