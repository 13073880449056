import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CarManufacturer } from 'src/app/models/car-manufacturer.model';
import { CarModel } from 'src/app/models/car-model.model';
import { YearModel } from 'src/app/models/year-model.model';
import { Engine } from 'src/app/models/engine.model';

import { Observable } from 'rxjs';
import { SelectedCar } from '../models/selected-car.model';
@Injectable({
  providedIn: 'root',
})
export class ShowCarService {
  readonly APIUrl = 'https://api.pendlingskalkylatorn.se/api';

  constructor(private http: HttpClient) {}

  //************ This is for CommutingCalculatorAPI ****************** */

  /** Get Selected Cars from database and return observable SelectedCar*/
  GetSelectedCars(): Observable<SelectedCar[]> {
    return this.http.get<SelectedCar[]>(`${this.APIUrl}/SelectedCar`);
  }

  // ************** This is for the CommutingAPI **************

  /** Get carmanufacturers from database and return observable CarManufactures[]  */
  /* GetCarManufacturers(): Observable<CarManufacturer[]> {
    return this.http.get<CarManufacturer[]>(`${this.APIUrl}/CarManufacturer`);
  } */

  /** Get carmodels from database and return observable CarModels[]  */
  /*  GetCarModels(): Observable<CarModel[]> {
    return this.http.get<CarModel[]>(`${this.APIUrl}/CarModel`);
  } */

  /** Get yearmodels from database and return observable YearModels[]  */
  /*  GetYearModels(): Observable<YearModel[]> {
    return this.http.get<YearModel[]>(`${this.APIUrl}/YearModel`);
  } */

  /** Get engines from database and return observable Engines[]  */
  /* GetEngines(): Observable<Engine[]> {
    return this.http.get<Engine[]>(`${this.APIUrl}/Engine`);
  } */
}
