<div class="container" fxLayout="column" fxLayoutGap="60px">

  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="description">
      <span>Här kan du jämföra olika resesätt med varandra och vilken påverkande på miljön de har.</span>
    </div>
  </div>

  <div class="container-step" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="30px">
    <app-chart></app-chart>


    <mat-tab-group mat-align-tabs="center">
      <mat-tab label="Bil">
        <app-alternative-info [travelMode]="'DRIVING'" [carEmissionsPerKm]="emissionsPerKm.emissionsCarbonDioxide"
                              (emissionsCalculated)="carEmissionsCalculated($event)"></app-alternative-info>
      </mat-tab>

      <mat-tab label="Kollektivtrafik">
        <app-alternative-info [travelMode]="'TRANSIT'"
                              (emissionsCalculated)="transitEmissionsCalculated($event)"></app-alternative-info>
      </mat-tab>

      <mat-tab label="Cykel">
        <app-alternative-info [travelMode]="'BICYCLING'"></app-alternative-info>
      </mat-tab>

      <mat-tab label="Promenad">
        <app-alternative-info [travelMode]="'WALKING'"></app-alternative-info>
      </mat-tab>

    </mat-tab-group>
  </div>

  <div class="info-text" fxLayout="column" fxLayoutAlign="center stretch">
      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
        <mat-icon>info_outline</mat-icon>
        <h3>Uträkningen baserar sig på en vald rutt utifrån valt resesätt. Vid beräkningen görs antagandet att man reser till och från en och samma plats 20 dagar per månad.</h3>
      </div>
  </div>

  <div class="stepper-buttons" fxLayout="row" fxLayoutAlign="space-between center" fxFill>
    <button mat-raised-button color="accent" matStepperPrevious>
      <mat-icon>navigate_before</mat-icon>
      <span>Bakåt</span>
    </button>

    <button mat-raised-button color="primary" matStepperNext>
      <span>Nästa</span>
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>

</div>

