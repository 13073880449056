import {Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {TripService} from "../../services/trip.service";
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, switchMap} from "rxjs/operators";
import {Observable} from "rxjs";
import AutocompletePrediction = google.maps.places.AutocompletePrediction;
import AutocompletionRequest = google.maps.places.AutocompletionRequest;
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import GeocoderRequest = google.maps.GeocoderRequest;

@Component({
  selector: 'app-mvp-details',
  templateUrl: './mvp-details.component.html',
  styleUrls: ['./mvp-details.component.scss']
})
export class MvpDetailsComponent implements OnInit {
  origin: any;
  destination: any;
  form: FormGroup = new FormGroup({});
  queryWait!: boolean;
  originNoResultError: boolean = false;
  destinationNoResultError: boolean = false;

  originResults: Observable<AutocompletePrediction[]> | undefined;
  destinationResults: Observable<AutocompletePrediction[]> | undefined;
  autocompleteService: google.maps.places.AutocompleteService | undefined;
  geocoder: google.maps.Geocoder | undefined;
  originJustSelected: boolean = false;
  destinationJustSelected: boolean = false;

  // PDF
  originText: string = "";
  destinationText: string = "";

  @ViewChild('originSearch')
  public originSearchElementRef!: ElementRef;
  @ViewChild('destinationSearch')
  public destinationSearchElementRef !: ElementRef;

  @Output('completed') completed: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private tripService: TripService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fb: FormBuilder
  ) {
    this.form = fb.group({
      originSearch: new FormControl(null, [Validators.required]),
      destinationSearch: new FormControl(null, [Validators.required]),
    });

    this.originResults = this.form
      .get('originSearch')!
      .valueChanges
      .pipe(
        debounceTime(500),
        switchMap(value => {
          let sameAsPrevious = this.originJustSelected;
          if (!this.originJustSelected) {
            this.origin = undefined;
            this.originText = "";
            this.completed.emit(false);
          }

          this.originJustSelected = false;
          return this.search(value, sameAsPrevious);
        })
      );

    this.destinationResults = this.form
      .get('destinationSearch')!
      .valueChanges
      .pipe(
        debounceTime(500),
        switchMap(value => {
          let sameAsPrevious = false;
          if (!this.destinationJustSelected) {
            this.destination = undefined;
            this.destinationText = "";
            this.completed.emit(false);
          }

          this.destinationJustSelected = false;
          return this.search(value, sameAsPrevious);
        })
      );
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.autocompleteService = new google.maps.places.AutocompleteService();
      this.geocoder = new google.maps.Geocoder();
    })
  }

  get f() {
    return this.form.controls;
  }

  search(text: string, sameAsPrevious: boolean): Observable<AutocompletePrediction[]> {
    if(text.length < 3 || sameAsPrevious){
      return new Observable((observer) => {
        observer.next([]);
        observer.complete();
      })
    }
    else{
      return new Observable((observer) => {
        if (this.autocompleteService !== undefined) {
          let request: AutocompletionRequest = {
            input: text,
            componentRestrictions: {
              country: "se"
            }
          };

          this.autocompleteService.getPlacePredictions(request, (result) => {
            this.ngZone.run(() => {
              observer.next(result);
              observer.complete();
            });
          });
        } else {
          observer.next([]);
          observer.complete();
        }
      });
    }
  }

  displayFn(prediction: AutocompletePrediction): string {
    return prediction?.description;
  }

  originSelected(event: MatAutocompleteSelectedEvent) {
    let option: AutocompletePrediction = event.option.value;

    let request: GeocoderRequest = {
      placeId: option.place_id
    };
    this.geocoder?.geocode(request, (results) => {
      this.ngZone.run(() => {
        if (results && results.length > 0) {
          let placeOrig = results[0];
          this.originText = placeOrig.formatted_address!;
          this.origin = {
            lat: placeOrig.geometry.location.lat(),
            lng: placeOrig.geometry.location.lng()
          };
          this.originJustSelected = true;
          this.originNoResultError = false;

          if (this.destination && this.origin) {
            if (this.destinationText === this.originText) {
              this.completed.emit(false);
            }
            else {
              this.completed.emit(true);
            }

            this.tripService.updateTripCoordinates({
              origin: this.origin,
              originText: this.originText,
              destination: this.destination,
              destinationText: this.destinationText,
            });
          }
        }
        else {
          this.originText = "";
          this.origin = undefined;
          this.originNoResultError = true;
        }
      });
    });
  }

  destinationSelected(event: MatAutocompleteSelectedEvent) {
    let option: AutocompletePrediction = event.option.value;

    let request: GeocoderRequest = {
      placeId: option.place_id
    };
    this.geocoder?.geocode(request, (results) => {
      this.ngZone.run(() => {
        if (results && results.length > 0) {
          let placeDest = results[0];
          this.destinationText = placeDest.formatted_address!;
          this.destination = {
            lat: placeDest.geometry.location.lat(),
            lng: placeDest.geometry.location.lng()
          };
          this.destinationJustSelected = true;
          this.destinationNoResultError = false;

          if (this.destination && this.origin) {
            if (this.destinationText === this.originText) {
              this.completed.emit(false);
            }
            else {
              this.completed.emit(true);
            }

            this.tripService.updateTripCoordinates({
              origin: this.origin,
              originText: this.originText,
              destination: this.destination,
              destinationText: this.destinationText,
            });
          }
        }
        else {
          this.destinationText = "";
          this.destination = undefined;
          this.destinationNoResultError = true;
        }
      });
    });
  }
}
