import {Component, Input, OnInit} from '@angular/core';
import {TripService} from "../../services/trip.service";
import {SelectedCar} from "../../models/selected-car.model";

@Component({
  selector: 'app-alternative-comparison',
  templateUrl: './alternative-comparison.component.html',
  styleUrls: ['./alternative-comparison.component.scss']
})
export class AlternativeComparisonComponent implements OnInit {
  @Input() emissionsPerKm:SelectedCar = {
    id: 0,
    fuel: 0,
    carSize: 0,
    yearModel: 0,
    emissionsCarbonDioxide: 0,
  };

  public calculatedCarEmission: number = 0;
  public calculatedTransitEmission: number = 0;


  constructor(
    private tripService: TripService
  ) {}

  ngOnInit(): void {
  }

  carEmissionsCalculated(emissions: number) {
    this.calculatedCarEmission = emissions;

    this.tripService.UpdateTripEmmisions({
      calculatedCarEmission: this.calculatedCarEmission,
      calculatedTransitEmission: this.calculatedTransitEmission,
    });
  }

  transitEmissionsCalculated(emissions: number) {
    this.calculatedTransitEmission = emissions;

    this.tripService.UpdateTripEmmisions({
      calculatedCarEmission: this.calculatedCarEmission,
      calculatedTransitEmission: this.calculatedTransitEmission,
    });
  }
}
