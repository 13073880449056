<mat-list>
  <mat-list-item *ngFor="let step of directionSteps">
    <mat-icon *ngIf="step.travelMode == 0" mat-list-icon>directions_walk</mat-icon>
    <mat-icon *ngIf="step.travelMode == 1" mat-list-icon>directions_bus</mat-icon>
    <mat-icon *ngIf="step.travelMode == 2" mat-list-icon>directions_transit</mat-icon>
    <mat-icon *ngIf="step.travelMode == 3" mat-list-icon>directions_boat</mat-icon>
    <mat-icon *ngIf="step.travelMode == 4" mat-list-icon>directions_car</mat-icon>
    <mat-icon *ngIf="step.travelMode == 5" mat-list-icon>directions_bike</mat-icon>

    <div *ngIf="!step.isTransit" [innerHTML]="step.instructions" mat-line></div>
    <div *ngIf="!step.isTransit" mat-line>{{step.distance}}, {{step.duration}}</div>

    <div *ngIf="step.isTransit" mat-line>{{step.instructions}}, Linje: {{step.transitLine}}</div>
    <div *ngIf="step.isTransit" mat-line>Avgång: {{step.departureStop}}, Tid: {{step.departureTime}}</div>
    <div *ngIf="step.isTransit" mat-line>Ankomst: {{step.arrivalStop}}, Tid: {{step.arrivalTime}}</div>
  </mat-list-item>
</mat-list>
